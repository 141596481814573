// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("/opt/build/repo/src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-register-now-tsx": () => import("/opt/build/repo/src/pages/register-now.tsx" /* webpackChunkName: "component---src-pages-register-now-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("/opt/build/repo/src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-thanks-for-registering-tsx": () => import("/opt/build/repo/src/pages/thanks-for-registering.tsx" /* webpackChunkName: "component---src-pages-thanks-for-registering-tsx" */)
}

